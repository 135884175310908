import { useCollection } from "../../hooks/useCollection"

import RecipesList from "../../components/RecipesList"

export default function Recipes() {
    const { documents: recipes } = useCollection("Recipes")
    console.log("recipes:", recipes)

    return (
        <div>
            <RecipesList recipes={recipes} />
        </div>
    )
}