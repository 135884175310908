import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext"

// page components
import Home from "./pages/home/Home"
import Recipes from "./pages/recipes/Recipes"
import Login from "./pages/login/Login"
import Create from "./pages/create/Create"
import Navbar from "./components/Navbar"

// import ThemeSelector from "./components/ThemeSelector"

// styles
import "./App.css"
//import { useTheme } from "./hooks/useTheme"

function App() {
  //const { mode } = useTheme()
  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App dark">
      {authIsReady && (
      <BrowserRouter>
        <Navbar />
        {/* <ThemeSelector /> */}
        <div className="Container">
          <Routes>
            <Route path="/" element={user ? <Home /> : <Login />} />
            <Route path="/recipes" element={user ? <Recipes /> : <Login/>} />
            <Route path="/login" element={!user ? <Login /> : <Home />} />
            <Route path="/create" element={user ? <Create /> : <Login /> } />
          </Routes>
        </div>
      </BrowserRouter>
      )}
    </div>
  )
}

export default App
