import { Link } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"

//styles
import "./Navbar.css"

export default function Navbar() {
    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleLogout = () => {
        logout()
    }

    return (
        <div className="navbar">
            <nav>
                <Link to="/" className="brand">
                    <h1>Recipes</h1>
                </Link>
                <Link className="navbutton" to="/recipes">All Recipes</Link>
                {user && <Link className="navbutton" onClick={handleLogout}>Logout</Link>}
                <Link to="/create">Add</Link>
            </nav>
        </div>
    )
}
