import { useCollection } from "../../hooks/useCollection"

//styles
//import "./Home.css"

import RecipesList from "../../components/RecipesList"

export default function Home() {
    const { documents: favorites } = useCollection('Recipes', ["favorite", "==", true])
    console.log("favorites:", favorites)

    return (
        <div>
            <RecipesList recipes={favorites} />
        </div>
    )
}
