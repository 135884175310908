import { initializeApp } from "firebase/app"
import { getDoc, doc, getFirestore, Timestamp, updateDoc, arrayRemove } from "firebase/firestore"
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyAMcMB02HmyIcwX6zjWFYskOq346OGBfu0",
    authDomain: "recipe-site-a5fb0.firebaseapp.com",
    projectId: "recipe-site-a5fb0",
    storageBucket: "recipe-site-a5fb0.appspot.com",
    messagingSenderId: "868057220509",
    appId: "1:868057220509:web:81f7035e2e6a915f435b74"
  };

// init firebase
initializeApp(firebaseConfig)

// init firestore
const db = getFirestore()

// init firebase auth
const auth = getAuth()

export { db, doc, getDoc, updateDoc, Timestamp, arrayRemove, auth }
