import { useState, useRef } from "react"
import { db, Timestamp } from "../../firebase/config"
import { collection, addDoc } from "firebase/firestore"

// styles
import "./Create.css"

export default function Create() {
    // form field values
    const [title, setTitle] = useState("")
    const [newIngredient, setNewIngredient] = useState('')
    const [ingredients, setIngredients] = useState([])
    const ingredientInput = useRef(null)
    const [newStep, setNewStep] = useState('')
    const [steps, setSteps] = useState([])
    const stepInput = useRef(null)

    const [directions, setDirections] = useState('')
    const [cookingTime, setCookingTime] = useState('')

    const handleSubmit = async e => {
        e.preventDefault()
        alert("submit clicked")

        const ref = collection(db, "Recipes")
        console.log('data:', title, ingredients)

        await addDoc(ref, {
            title: title.trim(),
            ingredients: ingredients,
            steps: steps,
            //   dueDate: Timestamp.fromDate(new Date(dateStr)),
            //   status: "Open",
        })
    }

    const handleAddIngredient = e => {
        e.preventDefault()
        const ing = newIngredient.trim()

        if (ing && !ingredients.includes(ing)) {
            setIngredients(prevIngredients => [...prevIngredients, ing])
        }
        setNewIngredient('')
        ingredientInput.current.focus()
    }

    const handleAddStep = e => {
        e.preventDefault()
        const step = newStep.trim()

        if (step && !steps.includes(step)) {
            setSteps(prevSteps => [...prevSteps, step])
        }
        setNewStep('')
        stepInput.current.focus()
    }

    return (
        <div className="create">
            <h2 className="page-title">Create a new Recipe</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>Title:</span>
                    <input required type="text" onChange={e => setTitle(e.target.value)} value={title} />
                </label>
                <label>
                    <span>Ingredients:</span>
                    <div className="ingredients">
                        <input type="text" onChange={(e) => setNewIngredient(e.target.value)} value={newIngredient} ref={ingredientInput} />
                        <button className="btn" onClick={handleAddIngredient}>add</button>
                    </div>
                    <ul>
                    {ingredients && ingredients.map(ing => (
                        <li key={ing}>{ing}</li>
                    ))}
                    </ul>
                </label>
                <label>
                    <span>Steps:</span>
                    <div className="ingredients">
                        <input type="text" onChange={(e) => setNewStep(e.target.value)} value={newStep} ref={stepInput}/>
                        <button className="btn" onClick={handleAddStep}>add</button>
                    </div>
                    <ul>
                    {steps && steps.map(step => (
                        <li key={step}>{step}</li>
                    ))}
                    </ul>
                </label>
                <button className="btn">submit</button>
            </form>
        </div>
    )
}
